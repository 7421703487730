<template>
  <div>
    <!--Content_all-->
    <section class="content_all cont_int product_intern produc_c2">
      <Header></Header>
      <div class="wrapper_m">
        <section class="titulo">
          <h1>
            <a href="web-responsive">WEB RESPONSIVE</a>
          </h1>
          <div class="tab_tittle right">
            <a href="web-responsive-caracteristicas">
              <span>Características</span>
            </a>
            <!--<a href="web-responsive-detail">Precio</a>-->
          </div>
        </section>
      </div>

      <!--Descripcion-->
      <section class="wrapper_m p_b_30">
        <ul class="detail_1">
          <li class="paddg block">
            <section class="flex">
              <div class="small-12 medium-6 large-7 columns img_full">
                <img src="assets/images/productos/responsive/img1.png" />
              </div>
              <div class="small-12 medium-6 large-5 columns">
                <div class="txt">
                  <h2 class="morado">Para computadores, smartphones y tablets</h2>
                  <p>Una página web que tus clientes pueden utilizar en cualquiera de sus dispositivos electrónicos.</p>
                </div>
              </div>
            </section>
          </li>
          <li class="paddg block">
            <section class="flex">
              <div class="small-12 medium-6 large-5 columns">
                <div class="txt txt_right">
                  <h2 class="morado">
                    Informa y vende
                    <br />a tus clientes
                  </h2>
                  <p>Sabías que el 25% de los clientes buscan la página web del restaurante antes de visitarlo, transforma esa búsqueda informativa en una venta.</p>
                </div>
              </div>
              <div class="small-12 medium-6 large-7 columns img_full">
                <img src="assets/images/productos/responsive/img2.png" />
              </div>
            </section>
          </li>
          <li class="paddg block">
            <section class="flex">
              <div class="small-12 medium-6 large-7 columns">
                <img src="assets/images/productos/responsive/img3.png" />
              </div>
              <div class="small-12 medium-6 large-5 columns">
                <div class="txt">
                  <h2 class="morado">Optimizada para los buscadores</h2>
                  <p>Los metadatos (datos no visibles) son configurados para ayudar a optimizar las búsquedas en Google, Yahoo, Bing y otras páginas web con contenido relacionado al de tu restaurante.</p>
                </div>
              </div>
            </section>
          </li>
          <li class="paddg block">
            <section class="flex">
              <div class="small-12 medium-6 large-5 columns">
                <div class="txt txt_right">
                  <h2 class="morado">Pagar en línea nunca fue más fácil</h2>
                  <p>Tus clientes podrán añadir diferentes tarjetas de crédito en la web para pagar en línea. Sin necesidad de datáfono o efectivo a la hora de entregar domicilios.</p>
                </div>
              </div>
              <div class="small-12 medium-6 large-7 columns">
                <img src="assets/images/productos/responsive/img4.png" />
              </div>
            </section>
          </li>
        </ul>
      </section>
      <!--Fin Descripcion-->
      <Footer></Footer>
    </section>
    <!--Fin Content_all-->
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Home',
  components: {
    Header,
    Footer
  }
}
</script>
