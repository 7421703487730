<template>
  <div>
    <!--Content_all-->
    <section class="content_all cont_int product_intern produc_c2">
      <Header></Header>
      <div class="wrapper_m">
        <section class="titulo">
          <h1>
            <a href="app-movil">APP MOVIL</a>
          </h1>
          <div class="tab_tittle right">
            <a href="app-movil-caracteristicas" class="active">
              <span>Características</span>
            </a>
            <!--<a href="app-movil-detail">Precio</a>-->
          </div>
        </section>
      </div>

      <!--Descripcion-->
      <section class="wrapper_m p_b_40">
        <ul class="list_detail_prod">
          <li class="block">
            <div class="small-12 medium-4 large-5 columns">
              <h3>Personalización</h3>
            </div>
            <div class="small-12 medium-8 large-7 columns">
              <div class="small-12 medium-6 large-6 columns">
                <div class="item_det">
                  <h2>Nombre</h2>
                  <p>Se monta a las tiendas con el nombre de su marca</p>
                </div>
              </div>
              <div class="small-12 medium-6 large-6 columns">
                <div class="item_det">
                  <h2>Icono y colores</h2>
                  <p>La aplicación se personaliza con los colores y fuente tipográﬁca de su marca, el ícono es el logo de su restaurante.</p>
                </div>
              </div>
            </div>
          </li>

          <li class="block">
            <div class="small-12 medium-4 large-5 columns">
              <h3>Modelos de venta</h3>
              <div class="item_det">
                <p>Son las formas que este producto tiene habilitadas a través de las cuales sus clientes pueden comprar a su restaurante.</p>
              </div>
            </div>
            <div class="small-12 medium-8 large-7 columns">
              <div class="small-12 medium-6 large-6 columns">
                <div class="item_det">
                  <h2>Eat-in</h2>
                  <p>Los usuarios pueden pedir para que esté listo y comer en el punto de venta</p>
                </div>
                <div class="item_det">
                  <h2>Delivery</h2>
                  <p>Los usuarios pueden hacer un pedido para su casa. El usuario escoge la dirección, se valida la cobertura y los horarios que usted ha conﬁgurado para domicilio</p>
                </div>
              </div>
              <div class="small-12 medium-6 large-6 columns">
                <div class="item_det">
                  <h2>Take Out</h2>
                  <p>Los usuarios pueden pedir para que esté listo y recogerlo para llevar</p>
                </div>
              </div>
            </div>
          </li>

          <li class="block">
            <div class="small-12 medium-4 large-5 columns">
              <h3>Pagos</h3>
            </div>
            <div class="small-12 medium-8 large-7 columns">
              <div class="small-12 medium-6 large-6 columns">
                <div class="item_det">
                  <h2>Efectivo o datáfono</h2>
                  <p>El usuario tiene la opción de escoger pago en efectivo o que su domiciliario lleve datafono para pagar cuando recibe el pedido</p>
                </div>
              </div>
              <div class="small-12 medium-6 large-6 columns">
                <div class="item_det">
                  <h2>Pago online</h2>
                  <p>El usuario tiene la posibilidad de registrar una tarjeta de crédito y pagar anticipado el pedido</p>
                </div>
              </div>
            </div>
          </li>

          <li class="block">
            <div class="small-12 medium-4 large-5 columns">
              <h3>Cuenta del cliente</h3>
            </div>
            <div class="small-12 medium-8 large-7 columns">
              <div class="small-12 medium-6 large-6 columns">
                <div class="item_det">
                  <h2>Direcciones</h2>
                  <p>El usuario puede registrar varias direcciones para recibir sus pedidos a domicilio</p>
                </div>
                <div class="item_det">
                  <h2>Pedido en curso</h2>
                  <p>El usuario puede hacer un seguimiento del estado del pedido que tiene en curso</p>
                </div>
                <div class="item_det">
                  <h2>Facebook Login</h2>
                  <p>Con sus datos de facebook el usuario queda automáticamente registrado en su base de datos</p>
                </div>
              </div>
              <div class="small-12 medium-6 large-6 columns">
                <div class="item_det">
                  <h2>Tarjetas</h2>
                  <p>El usuario puede registrar una o varias tarjetas de crédito</p>
                </div>
                <div class="item_det">
                  <h2>Datos de acceso</h2>
                  <p>El usuario puede modiﬁcar sus datos de acceso como el correo y el teléfono.</p>
                </div>
              </div>
            </div>
          </li>

          <li class="block">
            <div class="small-12 medium-4 large-5 columns">
              <h3>Carro de compras y checkout</h3>
            </div>
            <div class="small-12 medium-8 large-7 columns">
              <div class="small-12 medium-6 large-6 columns">
                <div class="item_det">
                  <h2>Resumen del pedido</h2>
                  <p>El usuario puede ver el resumen de su compra y conﬁrmar</p>
                </div>
                <div class="item_det">
                  <h2>Modiﬁcar pedido</h2>
                  <p>El usuario puede puede remover un producto de su pedido, editar las cantidades, hacer sugerencias propias de la compra</p>
                </div>
              </div>
              <div class="small-12 medium-6 large-6 columns">
                <div class="item_det">
                  <h2>Método de pago</h2>
                  <p>El usuario puede escoger cómo va a pagar su compra.</p>
                </div>
                <div class="item_det">
                  <h2>Promo Code</h2>
                  <p>El usuario puede aplicar un promo code para obtener el respectivo descuento.</p>
                </div>
              </div>
            </div>
          </li>

          <li class="block">
            <div class="small-12 medium-4 large-5 columns">
              <h3>Otras características</h3>
            </div>
            <div class="small-12 medium-8 large-7 columns">
              <div class="small-12 medium-6 large-6 columns">
                <div class="item_det">
                  <h2>Buscador</h2>
                  <p>El usuario puede buscar un producto o una categoría por su nombre.</p>
                </div>
              </div>
              <div class="small-12 medium-6 large-6 columns">
                <div class="item_det">
                  <h2>Ayuda</h2>
                  <p>El usuario puede solicitar ayuda y verá el teléfono de contacto o la posibilidad de escribir un email.</p>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <div class="clear"></div>
      </section>
      <!--Fin Descripcion-->

      <Footer></Footer>
    </section>
    <!--Fin Content_all-->
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Home',
  components: {
    Header,
    Footer
  }
}
</script>
