<template>
  <div>
    <!--Content_all-->
    <section class="content_all cont_int product_intern produc_c2">
      <Header></Header>
      <div class="wrapper_m">
        <section class="titulo">
          <h1>
            <router-link to="/brain">BRAIN</router-link>
          </h1>
          <div class="tab_tittle right">
            <router-link to="/brain-caracteristicas">
              <span>Características</span>
            </router-link>
            <!--<a href="brain-detail">Precio</a>-->
          </div>
        </section>
      </div>

      <!--Descripcion-->
      <section class="wrapper_m p_b_30">
        <ul class="detail_1">
          <li class="paddg block">
            <section class="flex">
              <div class="small-12 medium-6 large-5 columns">
                <div class="txt txt_right">
                  <h2 class="morado">Integración con Hardware</h2>
                  <p>Conecta tu sistema de impresoras y datafonos a nuestro sistema para administrar todo tu restaurante desde un app inteligente.</p>
                </div>
              </div>
              <div class="small-12 medium-6 large-7 columns">
                <img src="/assets/images/productos/brain/img1.jpg" class="img_grand" />
              </div>
            </section>
          </li>
          <li class="paddg block">
            <section class="flex">
              <div class="small-12 medium-6 large-7 columns">
                <img src="/assets/images/productos/brain/img2.jpg" class="img_grand2" />
              </div>
              <div class="small-12 medium-6 large-5 columns">
                <div class="txt">
                  <h2 class="morado">Todas tus órdenes en un solo lugar</h2>
                  <p>Con Brain recibirás todos los pedidos realizados por tus canales de venta online en una sola pantalla, para que tu cocina no pierda el orden a la hora de despacharlos.</p>
                </div>
              </div>
            </section>
          </li>

          <li class="paddg block">
            <section class="flex">
              <div class="small-12 medium-6 large-5 columns">
                <div class="txt txt_right">
                  <h2 class="morado">Analytics</h2>
                  <p>Recibe información organizada sobre el comportamiento de tus clientes frente a tus productos y canales. Utiliza esta información para implementar estrategias o abrir nuevos puntos de venta.</p>
                </div>
              </div>
              <div class="small-12 medium-6 large-7 columns">
                <img src="/assets/images/productos/brain/img3.jpg" />
              </div>
            </section>
          </li>
          <li class="paddg block">
            <section class="flex">
              <div class="small-12 medium-6 large-7 columns">
                <img src="/assets/images/productos/brain/img4.jpg" />
              </div>
              <div class="small-12 medium-6 large-5 columns">
                <div class="txt">
                  <h2 class="morado">Controla tus inventarios</h2>
                  <p>IOD Brain te brinda información sobre cuantos productos fueron vendidos a través de tus canales onlines, ten total control sobre tus productos en las ordenes.</p>
                </div>
              </div>
            </section>
          </li>
        </ul>
      </section>
      <!--Fin Descripcion-->
      <Footer></Footer>
    </section>
    <!--Fin Content_all-->
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Home',
  components: {
    Header,
    Footer
  }
}
</script>
