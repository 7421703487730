<template>
  <div>
  <Accordions />
  <footer>
    <section class="wrapper_l padding wow fadeIn accordion">
      <div class="col_foot padding">
        <h6>
          PRODUCTOS
          <a href="javascript:void(0)">
            <i class="fa fa-caret-down" aria-hidden="true"></i>
          </a>
        </h6>
        <ul class="listnav">
          <li>
            <router-link to="/brain">Brain</router-link>
          </li>
          <li>
            <router-link to="/messenger-bot">Messenger Bot</router-link>
          </li>
          <li>
            <router-link to="/web-responsive">Web Responsive</router-link>
          </li>
          <li>
            <router-link to="/app-movil">Premium App</router-link>
          </li>
          <li>
            <router-link to="/marketing">Marketing</router-link>
          </li>
        </ul>
      </div>
      <div class="col_foot padding">
        <h6>
          MODELOS DE VENTA
          <a href="javascript:void(0)">
            <i class="fa fa-caret-down" aria-hidden="true"></i>
          </a>
        </h6>
        <ul class="listnav">
          <li>
            <router-link to="/como-funciona">Eat In</router-link>
          </li>
          <li>
            <router-link to="/como-funciona">Take Out</router-link>
          </li>
          <li>
            <router-link to="/como-funciona">Delivery</router-link>
          </li>
        </ul>
      </div>
      <div class="col_foot padding">
        <h6>
          REDES
          <a href="javascript:void(0)">
            <i class="fa fa-caret-down" aria-hidden="true"></i>
          </a>
        </h6>
        <ul class="listnav">
          <li>
            <a href="https://www.facebook.com/InOutDelivery/?fref=ts" target="_blank">Facebook</a>
          </li>
          <li>
            <a href="https://www.instagram.com/inout.delivery/" target="_blank">Instagram</a>
          </li>
          <li>
            <a href="https://twitter.com/InOutDelivery" target="_blank">Twitter</a>
          </li>
        </ul>
      </div>
      <div class="col_foot padding">
        <h6>
          VENTAS
          <a href="javascript:void(0)">
            <i class="fa fa-caret-down" aria-hidden="true"></i>
          </a>
        </h6>
        <ul class="listnav">
          <!-- <li><a href="">Solicitar un demo</a></li> -->
          <li>
            <a
              onclick="window.open('http://m.me/InOutDelivery', 'Chat en linea', 'width=600px, height=600px')"
            >Chat en linea</a>
          </li>
          <li>
            <a
              href="https://www.youtube.com/watch?v=yUAE7ei4TjI"
              class="modalvideo"
              data-effect="mfp-zoom-out"
            >Video comercial</a>
          </li>
          <li>
            <a
              href="https://pipedrivewebforms.com/form/6e21957e546eed3fa9b9081d69ab5e2f610200"
              target="_blank"
            >Solicita un demo</a>
          </li>
          <li>
            <a href>+57 4 5167770 Ext. 1176</a>
          </li>
        </ul>
      </div>
      <div class="col_foot padding bg_leng">
        <h6>
          COLOMBIA
          <div class="leng">
            <a href="javascript:void(0)">
              <img src="assets/images/icon_es.png" />
              <i class="fa fa-caret-down" aria-hidden="true"></i>
            </a>
          </div>
        </h6>
        <ul class="listnav">
          <!-- <li><a href="registrarme">Crear Cuenta</a></li> -->
          <!-- <li><a href="iniciar-sesion">Ingresar</a></li> -->
          <li>
            <router-link to="/contrato">Contrato</router-link>
          </li>
          <li>
            <a href>Soporte</a>
          </li>
        </ul>
      </div>
      <div class="clear"></div>
      <div class="dates_f">
        <p>
          <a href="tel:+57 4 5167770">+57 4 5167770</a> Ext. 1176
        </p>
        <p>
          <a href="mailto:soporte@inoutdelivery.com">soporte@inoutdelivery.com</a>
        </p>
        <p>
          <a href="mailto:ventas@inoutdelivery.com">ventas@inoutdelivery.com</a>
        </p>
      </div>
    </section>

    <section class="wrapper_l wow fadeIn padding">
      <div class="copy">
        <router-link class="logo_f" to="/">
          <img src="assets/images/logo.svg" alt="inoutdelivery.com" />
        </router-link>
        <p>Todos los derechos reservados © 2016</p>
      </div>
    </section>
  </footer>
  </div>
</template>

<script>
import Accordions from '@/components/Accordions.vue'

export default {
  name: 'Footer',
  components: {
    Accordions
  }
}
</script>

<style>
@media only screen and (max-width: 642px) {
  .accordion{
    display: none;
  }
}
</style>
