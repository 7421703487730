<template>
  <div>
    <!--Content_all-->
    <section class="content_all cont_int product_intern produc_c2">
      <Header></Header>
      <div class="wrapper_m">
        <section class="titulo">
          <h1>
            <a href="app-movil">APP MOVIL</a>
          </h1>
          <div class="tab_tittle right">
            <a href="app-movil-caracteristicas">
              <span>Características</span>
            </a>
            <!--<a href="app-movil-detail">Precio</a>-->
          </div>
        </section>
      </div>

      <!--Descripcion-->
      <section class="wrapper_m">
        <ul class="detail_1">
          <li class="paddg block">
            <section class="flex">
              <div class="small-12 medium-6 large-5 columns">
                <div class="txt txt_right">
                  <h2 class="morado">Con el sello de tu marca por donde lo mires</h2>
                  <p>Todas nuestras apps están diseñadas para tener la mejor experiencia de compra, solo falta que tenga los productos, el logo y los colores de tu marca.</p>
                </div>
              </div>
              <div class="small-12 medium-6 large-7 columns">
                <img src="/assets/images/productos/apps/img1.png" class="img_grand" />
              </div>
            </section>
          </li>
          <li class="paddg block">
            <section class="flex">
              <div class="small-12 medium-6 large-7 columns">
                <img src="/assets/images/productos/apps/img2.png" />
              </div>
              <div class="small-12 medium-6 large-5 columns">
                <div class="txt">
                  <h2 class="morado">Para iOs y Android</h2>
                  <p>Desarrollada en lenguaje nativo para cada los sistemas operativos móviles más utilizados del mundo.</p>
                </div>
              </div>
            </section>
          </li>
          <li class="paddg block">
            <section class="flex">
              <div class="small-12 medium-6 large-5 columns">
                <div class="txt txt_right">
                  <h2 class="morado">Pagar en línea nunca fue más fácil</h2>
                  <p>Tus clientes podrán añadir diferentes tarjetas de crédito en la app para pagar en línea. Sin necesidad de datáfono o efectivo a la hora de entregar domicilios.</p>
                  <p>Sin datáfonos en el punto de venta, el App lo reemplaza, simplemente carga el pedido a un cliente y permítele pagar directamente desde su celular a un solo clic.</p>
                </div>
              </div>
              <div class="small-12 medium-6 large-7 columns">
                <img src="/assets/images/productos/apps/img3.png" />
              </div>
            </section>
          </li>
          <li class="paddg block">
            <section class="flex">
              <div class="small-12 medium-6 large-7 columns">
                <img src="/assets/images/productos/apps/img4.png" />
              </div>
              <div class="small-12 medium-6 large-5 columns">
                <div class="txt">
                  <h2 class="morado">Actualizaciones gratuitas</h2>
                  <p>Tu app nunca se quedará atrás, a medida que pase el tiempo nuestro equipo implementará los nuevos desarrollos de los sistemas operativos en tu app..</p>
                </div>
              </div>
            </section>
          </li>
          <li class="paddg_t block">
            <section class="flex">
              <div class="small-12 medium-6 large-5 columns">
                <div class="txt txt_right">
                  <h2 class="morado">Escucha a tus clientes</h2>
                  <p>La mejor estrategia para incrementar las ventas y la fidelización de los clientes es escucharlos y conocerlos, saber qué compran, a qué hora, qué día y la calificación que le dan a cada experiencia, así sabrás qué puntos debes mejorar del servicio o el producto.</p>
                </div>
              </div>
              <div class="small-12 medium-6 large-7 columns">
                <img src="/assets/images/productos/apps/img5.png" />
              </div>
            </section>
          </li>
        </ul>
      </section>
      <!--Fin Descripcion-->

      <Footer></Footer>
    </section>
    <!--Fin Content_all-->
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Home',
  components: {
    Header,
    Footer
  }
}
</script>
