<template>
  <div>
    <!--Content_all-->
    <section class="content_all cont_int product_intern produc_c2">
      <Header></Header>
      <div class="wrapper_m">
        <section class="titulo">
          <h1>
            <router-link to="/app-movil">APP MOVIL</router-link>
          </h1>
          <div class="tab_tittle right">
            <router-link to="/app-movil-caracteristicas">
              <span>Características</span>
            </router-link>
            <!--<a href="app-movil-detail" class="active">Precio</a>-->
          </div>
        </section>
      </div>

      <!--Descripcion-->
      <section class="wrapper_m">
        <section class="paddg block">
          <section class="detail_product_price padding">
            <div class="small-12 medium-6 large-6 columns">
              <ul class="detail_slide">
                <li class="item">
                  <img src="/assets/images/productos/apps/detail1.png" />
                </li>
                <li class="item">
                  <img src="/assets/images/productos/apps/detail2.png" />
                </li>
              </ul>
            </div>
            <div class="small-12 medium-6 large-6 columns padding">
              <div class="txt">
                <h1>Comprar App Movil</h1>
                <h2 class="price">
                  $ 9.000.000
                  <span>COP</span>
                  <br />
                  <span>+ 4% de las ventas generadas por ese canal</span>
                </h2>
                <p>
                  * Incluye todas las
                  <router-link to="/app-movil-caracteristicas">
                    <span>características</span>
                  </router-link>
                </p>
                <p>* Sin cláusulas de permanencia.</p>
                <!-- <a href="" class="btnb blue m_b_20">CREAR UNA CUENTA GRATIS</a>   -->
                <p>¿Necesitas ayuda?</p>
                <p>
                  <a
                    onclick="window.open('http://m.me/InOutDelivery', 'Chat en linea', 'width=600px, height=600px')"
                  >Chatea ahora</a> o llamanos +57 319 790 7667
                </p>
              </div>
            </div>
          </section>
        </section>
        <div class="clear"></div>
      </section>
      <!--Fin Descripcion-->

      <section class="wrapper_m p_b_40">
        <!-- <div class="bg_acodeon">
            <a href="javascript:void(0)" class="modificador_btn">¿Cuánto se demora? <i class="fa fa-caret-down" aria-hidden="true"></i></a>
            <div class="cont_modif">
              <p>Lo único que necesitas es la voluntad de crecer tus ventas y mejorar la atención de tus clientes, nosotros nos encargamos del resto.</p>
              <p>Tienes que tener preparada lo siguiente:</p>
              <p>1.  La carta de tu restaurante, descargar formato.</p>
              <p>2. Los accesos como administrador a tu fanpage.</p>
              <p>3. Crear una cuenta de InOut Delivery.</p>
                <div class="clear"></div>
            </div>
        </div>-->
        <div class="bg_acodeon">
          <a href="javascript:void(0)" class="modificador_btn">
            ¿Qué necesito para empezar?
            <i class="fa fa-caret-down" aria-hidden="true"></i>
          </a>
          <div class="cont_modif">
            <p>Lo único que necesitas es la voluntad de crecer tus ventas y mejorar la atención de tus clientes, nosotros nos encargamos del resto.</p>
            <p>Tienes que tener preparada lo siguiente:</p>
            <p>1. La carta de tu restaurante, descargar formato.</p>
            <p>2. Los accesos como administrador a tu fanpage.</p>
            <p>3. Crear una cuenta de InOut Delivery.</p>
            <div class="clear"></div>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </section>
    <!--Fin Content_all-->
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Home',
  components: {
    Header,
    Footer
  }
}
</script>
