<template>
  <div>
    <carousel class="carousel-pos" v-bind="opc">
      <div>
        <img
          class="mask-slide1"
          width="100%"
          src="/assets/images/slider/slider1.png"
          alt="carousel-img"
        />
        <div class="text-carousel">
          <h1>Soluciones online para que tu restaurante se transforme en un gigante</h1>
          <a href="https://www.youtube.com/watch?v=yUAE7ei4TjI" target="_blank" rel="noreferrer">
            <i class="fa fa-caret-right" aria-hidden="true"></i> Ver el video
          </a>
        </div>
      </div>

      <div>
        <router-link to="/messenger-bot">
          <img width="100%" src="/assets/images/slider/slider2.png" alt="app-inoutdelivery" />
        </router-link>
      </div>

      <div>
        <router-link to="/como-funciona#omnicanalidad">
          <img width="100%" src="/assets/images/slider/slider3.png" alt="app-inoutdelivery" />
        </router-link>
      </div>
    </carousel>
  </div>
</template>

<script>
import carousel from 'vue-owl-carousel'

export default {
  components: {
    carousel
  },
  data () {
    return {
      opc: {
        items: 1,
        autoplay: true,
        autoplayTimeout: 8500,
        autoplaySpeed: 1900,
        nav: false,
        loop: true,
        dots: false
      }
    }
  }
}
</script>

<style>
.carousel-pos {
  margin-top: 3pc;
}

.text-carousel {
  position: absolute;
  top: 0%;
  margin-top: 55px;
  padding: 0 20px;
  z-index: 99;
}

.text-carousel h1 {
  font-family: "GothamRndBold", sans-serif;
  font-weight: 900;
  width: 29pc;
  margin-bottom: 10px;
  color: white;
  font-size: 35px;
  letter-spacing: 1px;
}

.text-carousel a {
  color: white;
}

.text-carousel a:hover {
  text-decoration: underline;
}

.mask-slide1 {
  filter: brightness(60%);
}

@media only screen and (max-width: 705px) {
  .text-carousel h1 {
    width: 100%;
  }
}

@media only screen and (max-width: 490px) {
  .text-carousel h1 {
    font-size: 20px;
  }

  .text-carousel {
    top: -20px;
  }
}
</style>
