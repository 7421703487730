<template>
  <div>
    <!--Content_all-->
    <section class="content_all cont_int product_intern">
      <Header></Header>
      <section class="nav_cat">
        <div class="wrapper_l">
          <ul class="product_nav">
            <li class="item">
              <a href="brain">
                <img src="/assets/images/productos/cat1.svg" />
                <span>BRAIN</span>
              </a>
            </li>
            <li class="item">
              <a href="messenger-bot">
                <img src="/assets/images/productos/cat2.svg" />
                <span>MENSSENGER BOT</span>
              </a>
            </li>
            <li class="item">
              <a href="web-responsive">
                <img src="/assets/images/productos/cat3.svg" />
                <span>WEB RESPONSIVE</span>
              </a>
            </li>
            <li class="item">
              <a href="app-movil">
                <img src="/assets/images/productos/cat4.svg" />
                <span>APP MOVIL</span>
              </a>
            </li>
            <li class="item">
              <a href="marketing">
                <img src="/assets/images/productos/cat6.svg" />
                <span>MARKETING</span>
              </a>
            </li>
          </ul>
        </div>
      </section>

      <!--Productos-->
      <section class="product_all">
        <section class="all_cat cat_1 paddg_t bg_black txt_blan">
          <section class="wrapper_m">
            <div class="block">
              <h1>BRAIN</h1>
              <h2>Tu gerente administrativo de domicilios</h2>
              <p>El POS InOut es el centro de la Omnicanalidad para el restaurante, conecta toda la información de los puntos de venta físicos, el call center y los canales de venta online.</p>
            </div>
            <div class="centerBtn m_b_50">
              <a href="brain" class="link">Ver producto</a>
              <!--<a href="brain-detail" class="link">Precios</a>-->
            </div>
            <a href="brain">
              <img src="/assets/images/productos/img_cat1.png" alt="Brain" class="wow fadeIn" />
            </a>
          </section>
        </section>

        <section class="all_cat cat_2 paddg">
          <section class="wrapper_s">
            <div class="block">
              <h1>
                MESSENGER B
                <span class="wow zoomIn">O</span>T
              </h1>
              <h2>Transforma tu marca en la mejor amiga de tus clientes</h2>
              <p>Vende tus productos en una de las aplicaciones favoritas de tus clientes, ellos piden por facebook messenger, donde un bot inteligente responde automáticamente y el pedido llega a tu cocina.</p>
              <div class="centerBtn">
                <a href="messenger-bot" class="link">Ver producto</a>
                <!--<a href="messenger-bot-detail" class="link">Precios</a>-->
              </div>
            </div>
            <a href="messenger-bot">
              <img
                src="/assets/images/productos/img_cat2.png"
                alt="messenger-bot"
                class="wow fadeIn"
              />
            </a>
          </section>
        </section>

        <section class="all_cat bg_gris cat_3 paddg_t">
          <section class="wrapper_m">
            <div class="block">
              <h1>WEB RESPONSIVE</h1>
              <h2>Transforma una visita informativa en una venta</h2>
              <p>Ten presencia en todos los dispositivos conectados a internet por medio de una página web donde tus clientes podran informarse sobre tus puntos de venta y de paso pedir un domicilio</p>
              <div class="centerBtn">
                <a href="web-responsive" class="link">Ver producto</a>
                <!--<a href="web-responsive-detail" class="link">Precios</a>-->
              </div>
            </div>
            <a href="web-responsive">
              <img
                src="/assets/images/productos/img_cat3.png"
                alt="Web-reponsive"
                class="wow fadeIn"
              />
            </a>
          </section>
        </section>

        <section class="all_cat cat_4 paddg">
          <section class="wrapper_s">
            <div class="block">
              <h1>APP MÓVIL</h1>
              <h2>Un punto de venta en el bolsillo de tus clientes</h2>
              <p>Los smartphones son una extensión de las personas, pon tu restaurante en el mapa de todos tus usuarios, acércate a ellos estando en donde siempre están... su smartphone</p>
              <div class="centerBtn">
                <a href="app-movil" class="link">Ver producto</a>
                <!--<a href="app-movil-detail" class="link">Precios</a>-->
              </div>
            </div>
          </section>

          <CarouselMobile></CarouselMobile>
        </section>

        <section class="all_cat cat_6 paddg">
          <section class="wrapper_m">
            <div class="block">
              <h1>MARKETING</h1>
              <h2>El éxito no es casualidad</h2>
              <p>Implementa estrategias en redes y de cara a tus clientes para ganar el prestigio y reconocimiento que tu restaurante se merece.</p>
              <div class="centerBtn">
                <a href="marketing" class="link">Ver producto</a>
                <!--<a href="marketing-detail" class="link">Precios</a>-->
              </div>
              <a href="marketing">
                <img src="/assets/images/productos/cat_6.jpg" alt="Marketing" class="wow fadeIn" />
              </a>
            </div>
          </section>
        </section>
      </section>
      <!--Fin Productos-->
      <Footer></Footer>
    </section>
    <!--Fin Content_all-->
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import CarouselMobile from '@/components/CarouselMobile'

export default {
  name: 'Home',
  components: {
    Header,
    Footer,
    CarouselMobile
  }
}
</script>
