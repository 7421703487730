<template>
  <div>
    <!--Content_all-->
    <section class="content_all cont_int product_intern produc_c2">
      <Header></Header>
      <div class="wrapper_m">
        <section class="titulo">
          <h1>
            <router-link to="/brain">BRAIN</router-link>
          </h1>
          <div class="tab_tittle right">
            <router-link to="/brain-caracteristicas">
              <span>Características</span>
            </router-link>
            <!--<a href="brain-detail">Precio</a>-->
          </div>
        </section>
      </div>

      <!--Descripcion-->
      <section class="wrapper_m p_b_40">
        <ul class="list_detail_prod">
          <li class="block">
            <div class="small-12 medium-4 large-5 columns">
              <h3>Integración con el POS</h3>
            </div>
            <div class="small-12 medium-8 large-7 columns">
              <div class="small-12 medium-6 large-6 columns">
                <div class="item_det">
                  <h2>Facturación Automática</h2>
                  <p>Las ventas online por cualquier canal quedan facturadas automáticamente, evitando reprocesos y sobre costos de atención al teléfono</p>
                </div>
              </div>
              <div class="small-12 medium-6 large-6 columns">
                <div class="item_det">
                  <h2>Sincronización de inventarios</h2>
                  <p>Inventario sincronizado con las ventas de todos los canales que la marca maneja.</p>
                </div>
              </div>
            </div>
          </li>

          <li class="block">
            <div class="small-12 medium-4 large-5 columns">
              <h3>Reportes y analytics</h3>
              <div class="item_det">
                <p>Cada uno de los reportes según su naturaleza, tienen la posibilidad de ser ﬁltrados por fechas, ciudad o por punto de venta.</p>
              </div>
            </div>
            <div class="small-12 medium-8 large-7 columns">
              <div class="small-12 medium-6 large-6 columns">
                <div class="item_det">
                  <h2>Ventas</h2>
                  <p>Las ventas online por cualquier canal quedan facturadas automáticamente, evitando reprocesos y sobre costos de atención al teléfono</p>
                </div>
                <div class="item_det">
                  <h2>Productos más vendidos</h2>
                  <p>Listado de los productos que más se venden</p>
                </div>
                <div class="item_det">
                  <h2>Ventas por punto de venta</h2>
                  <p>Curva de ventas por canal y por punto de venta</p>
                </div>
                <div class="item_det">
                  <h2>Segmentación de clientes</h2>
                  <p>Parametrización de los comportamientos de compra para segmentar sus clientes y automatizar procesos de comunicación.</p>
                  <p>· Clientes que llevan 1 mes sin comprar.</p>
                  <p>· Clientes que gastaron más de cierta cantidad de dinero en el último mes.</p>
                </div>
                <div class="item_det">
                  <h2>Embudos de conversión</h2>
                  <p>De las personas que entraron a su sitio, ¿Cuantas agregaron un producto al carrito? y de esas que agregaron un producto al carrito, ¿Cuantas hicieron una compra?</p>
                </div>
              </div>
              <div class="small-12 medium-6 large-6 columns">
                <div class="item_det">
                  <h2>Métodos de pago</h2>
                  <p>Inventario sincronizado con las ventas de todos los canales que la marca maneja</p>
                </div>
                <div class="item_det">
                  <h2>Ticket promedio</h2>
                  <p>Valor de las ventas sobre el número de transacciones</p>
                </div>
                <div class="item_det">
                  <h2>Categorias de productos más vendidos</h2>
                  <p>¿Qué se vende más, hamburguesas o ensaladas?</p>
                </div>
                <div class="item_det">
                  <h2>Top 10 clientes</h2>
                  <p>Listado de clientes que más veces compran y listado de los que más gastan</p>
                </div>
                <div class="item_det">
                  <h2>Retención</h2>
                  <p>¿Cuánto tiempo transcurre desde que un cliente le compró por primera vez hasta que le volvió a comprar?</p>
                  <p>¿Qué porcentaje de clientes vuelven a comprar en menos de 1 mes?</p>
                </div>
              </div>
            </div>
          </li>

          <li class="block">
            <div class="small-12 medium-4 large-5 columns">
              <h3>Inventario</h3>
            </div>
            <div class="small-12 medium-8 large-7 columns">
              <div class="small-12 medium-6 large-6 columns">
                <div class="item_det">
                  <h2>Disponibilidad, precios y cantidades</h2>
                  <p>Administre las cantidades que tiene disponibles por cada punto de venta y sus respectivos precios</p>
                </div>
                <div class="item_det">
                  <h2>Categorías y subcategorías</h2>
                  <p>Cree, edite y elimine todas las categorías y subcategorías que quiera</p>
                </div>
                <div class="item_det">
                  <h2>Imagenes de productos</h2>
                  <p>Administre con autonomía las fotos de sus productos</p>
                </div>
                <div class="item_det">
                  <h2>Orden y posicionamiento</h2>
                  <p>Puede asignar el orden en el que se muestran sus productos, si quiere impulsar una promoción o un combo</p>
                </div>
              </div>
              <div class="small-12 medium-6 large-6 columns">
                <div class="item_det">
                  <h2>Variaciones de productos</h2>
                  <p>Agregue modiﬁcadores para que sus clientes puedan agregar o quitar ingredientes, añadir adiciones y pedir en combo</p>
                </div>
                <div class="item_det">
                  <h2>Importar y exportar</h2>
                  <p>Importe y exporte su inventario desde un archivo CSV</p>
                </div>
                <div class="item_det">
                  <h2>Etiquetas SEO para productos</h2>
                  <p>Agregue fácilmente meta etiquetas a sus productos para que se optimicen en las búsquedas en google y otros buscadores</p>
                </div>
              </div>
            </div>
          </li>

          <li class="block">
            <div class="small-12 medium-4 large-5 columns">
              <h3>Administración de sus canalesde venta online</h3>
            </div>
            <div class="small-12 medium-8 large-7 columns">
              <div class="small-12 medium-6 large-6 columns">
                <div class="item_det">
                  <h2>Horarios, ubicación y datos de contacto</h2>
                  <p>Conﬁgure los horarios de cada punto de venta y su respectiva información de contacto</p>
                </div>
                <div class="item_det">
                  <h2>Zonas de cobertura</h2>
                  <p>Dibuje en un mapa las zonas de cobertura de domicilios que tiene cada uno de sus puntos de venta o puntos de distribución</p>
                </div>
              </div>
              <div class="small-12 medium-6 large-6 columns">
                <div class="item_det">
                  <h2>Puntos de venta</h2>
                  <p>Cree, edite y elimine los puntos de venta y/o de distribución que tiene su marca</p>
                </div>
                <div class="item_det">
                  <h2>Tiempos de entrega</h2>
                  <p>Puede conﬁgurar los horarios y los tiempos de entrega de domicilios para cada uno de los puntos de venta</p>
                </div>
              </div>
            </div>
          </li>

          <li class="block">
            <div class="small-12 medium-4 large-5 columns">
              <h3>CRM (Menejo y ﬁdelización)</h3>
            </div>
            <div class="small-12 medium-8 large-7 columns">
              <div class="small-12 medium-6 large-6 columns">
                <div class="item_det">
                  <h2>E-mail</h2>
                  <p>Noticias, promociones, invitaciones, lo que se te ocurra</p>
                </div>
                <div class="item_det">
                  <h2>Caliﬁcaciones</h2>
                  <p>Permita que sus clientes caliﬁquen cada servicio, así podrá hacer una mejor gestión de cada punto y canal de venta</p>
                </div>
              </div>
              <div class="small-12 medium-6 large-6 columns">
                <div class="item_det">
                  <h2>Notiﬁcaciones push</h2>
                  <p>Envíe notiﬁcaciones push a los usuarios de su app en el momento oportuno para generar una venta</p>
                </div>
                <div class="item_det">
                  <h2>Historial de compras</h2>
                  <p>Conozca a sus clientes, qué le compran, a qué horas, que días, dónde están ubicados</p>
                </div>
              </div>
            </div>
          </li>

          <li class="block">
            <div class="small-12 medium-4 large-5 columns">
              <h3>SEO y contenido</h3>
            </div>
            <div class="small-12 medium-8 large-7 columns">
              <div class="small-12 medium-6 large-6 columns">
                <div class="item_det">
                  <h2>Meta etiquetas</h2>
                  <p>Describa cada página de sus canales de venta para que se adapten mejor a los resultados de búsqueda</p>
                </div>
                <div class="item_det">
                  <h2>Páginas de contenido</h2>
                  <p>Cree, edite y elimine las páginas de contenido que quiera, con fotos, descripciones, videos y links de redes sociales</p>
                </div>
              </div>
              <div class="small-12 medium-6 large-6 columns">
                <div class="item_det">
                  <h2>Certiﬁcaciones de propiedad</h2>
                  <p>Conﬁguramos cada canal de venta en Facebook, Google, Yahoo y Bing para que sus canales de venta sean certiﬁcados como un sitio seguro</p>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <div class="clear"></div>
      </section>
      <!--Fin Descripcion-->

      <Footer></Footer>
    </section>
    <!--Fin Content_all-->
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'BrainFeautures',
  components: {
    Header,
    Footer
  }
}
</script>
