<template>
  <div>
    <!--Content_all-->
    <section class="content_all cont_int product_intern">
      <Header></Header>
      <div class="wrapper_m">
        <section class="titulo">
          <h1>
            <a href="messenger-bot">MESSENGER BOT</a>
          </h1>
          <div class="tab_tittle right">
            <a href="https://www.messenger.com/t/InOutBotDemo/" target="_blank">
              <span>Demo</span>
            </a>
            <a href="messenger-bot-caracteristicas" class="active">
              <span>Características</span>
            </a>
            <!--<a href="messenger-bot-detail">Precio</a>-->
          </div>
        </section>
      </div>

      <!--Descripcion-->
      <section class="wrapper_m p_b_40">
        <ul class="list_detail_prod">
          <li class="block">
            <div class="small-12 medium-4 large-5 columns">
              <h3>Lineas de servicio al cliente</h3>
            </div>
            <div class="small-12 medium-8 large-7 columns">
              <div class="small-12 medium-6 large-6 columns">
                <div class="item_det">
                  <h2>Ordenar</h2>
                  <p>Deje que su cliente oredene cualquiera de sus alternativas</p>
                </div>
                <div class="item_det">
                  <h2>Seguimiento de la orden</h2>
                  <p>Seguimiento en tiempo real sobre el estado de su orden, donde puede ver un mapa con la ubicación del domiciliario y el puneto de entrega</p>
                </div>
                <div class="item_det">
                  <h2>Facebook Login</h2>
                  <p>Con sus datos de facebook el usuario queda automáticamente registrado en su base de datos</p>
                </div>
                <div class="item_det">
                  <h2>Pago en linea</h2>
                  <p>Pagar una orden, una reserva o incluso, pagar la cuenta dentro del punto de venta</p>
                </div>
                <div class="item_det">
                  <h2>PQRs</h2>
                  <p>Puede poner una pregunta, queja o reclamo y obtener un tiquet para reclamo y obtener un tiquet para hacerle seguimiento</p>
                </div>
                <div class="item_det">
                  <h2>Contacto humano</h2>
                  <p>Tu cliente puede chatear con un humano en cualquier momento y recibirás una notiﬁcación en tu celular o callcenter</p>
                </div>
              </div>
              <div class="small-12 medium-6 large-6 columns">
                <div class="item_det">
                  <h2>Reservar</h2>
                  <p>Escoge el día y la hora de la reserva. Instruciones especiales, recordatorio automático, conﬁrmación y caliﬁcación de la experiencia</p>
                </div>
                <div class="item_det">
                  <h2>Caliﬁcar</h2>
                  <p>Luego de cada experiencia el usuario recibe un mensaje para caliﬁcar el servicio prestado</p>
                </div>
                <div class="item_det">
                  <h2>Registrar teléfono</h2>
                  <p>El usuario ingresa su teléfono para ﬁnalizar su registro</p>
                </div>
                <div class="item_det">
                  <h2>Localizador de tiendas</h2>
                  <p>El usuario puede encontrar los puntos de vanta más cercanos, consultar horarios y obtener su dirección</p>
                </div>
                <div class="item_det">
                  <h2>Agregar direcciones</h2>
                  <p>El usuario puede escoger a donde quiere que le entregen su orden y guardar esta direccón para próximos pedidos</p>
                </div>
              </div>
            </div>
          </li>

          <li class="block">
            <div class="small-12 medium-4 large-5 columns">
              <h3>Administración</h3>
            </div>
            <div class="small-12 medium-8 large-7 columns">
              <div class="small-12 medium-6 large-6 columns">
                <div class="item_det">
                  <h2>Inventario</h2>
                  <p>Administra la disponibilidad y precios de tus productos que vende por el Bot</p>
                </div>
                <div class="item_det">
                  <h2>Zonas de Cobertura</h2>
                  <p>Conﬁgura las zonas en las que haces entregas a domicilio</p>
                </div>
                <div class="item_det">
                  <h2>Roles de usuario</h2>
                  <p>Puedes crear diferentes usuarios con niveles de permisos para delegar funciones. Por ejemplo un perﬁl comercial para ver solo la información de los clientes y sus compras</p>
                </div>
              </div>
              <div class="small-12 medium-6 large-6 columns">
                <div class="item_det">
                  <h2>Puntos de venta</h2>
                  <p>Puedes administrar todostsus puntos de venta sin cargos adicionales, si abres nuevos puntos de venta los puedes agregar facilmente</p>
                </div>
                <div class="item_det">
                  <h2>Horarios</h2>
                  <p>Conﬁgura los horarios de atención para sus domicilios, reservas y atención al público</p>
                </div>
              </div>
            </div>
          </li>

          <li class="block">
            <div class="small-12 medium-4 large-5 columns">
              <h3>Fidelización y Marketing</h3>
            </div>
            <div class="small-12 medium-8 large-7 columns">
              <div class="small-12 medium-6 large-6 columns">
                <div class="item_det">
                  <h2>Mensajes</h2>
                  <p>Estas chateando con tus clientes, puedes enviar cualquier tipo de mensaje a cualquier momento</p>
                </div>
                <div class="item_det">
                  <h2>Tarjeta e regalo</h2>
                  <p>Deja que tus clientes inviten a comer a sus amigos y compartan experiencias a través de messenger</p>
                </div>
              </div>
              <div class="small-12 medium-6 large-6 columns">
                <div class="item_det">
                  <h2>Códigos promocionales</h2>
                  <p>Incentiva compras recurrentes y anima a nuevos clientes a probar yus productos con códigos promocionales que se validan en el Bot</p>
                </div>
                <div class="item_det">
                  <h2>Social Link</h2>
                  <p>Copia el link a tu Bot en todas tus redes sociales, en tu blog y en tu página web</p>
                </div>
              </div>
            </div>
          </li>

          <li class="block">
            <div class="small-12 medium-4 large-5 columns">
              <h3>Reportes</h3>
            </div>
            <div class="small-12 medium-8 large-7 columns">
              <div class="small-12 medium-6 large-6 columns">
                <div class="item_det">
                  <h2>Ventas</h2>
                  <p>Filtra las ventas por periodo de tiempo y por punyo de venta</p>
                </div>
                <div class="item_det">
                  <h2>Medios de pago</h2>
                  <p>Identiﬁca los medios de pago preferidos de tus clientes</p>
                </div>
                <div class="item_det">
                  <h2>Mapeo</h2>
                  <p>Visualiza en un mapa los puntos donde más vendes y de esta manera puedes maximixar tus impactos off-line</p>
                </div>
              </div>
              <div class="small-12 medium-6 large-6 columns">
                <div class="item_det">
                  <h2>Los más vendidos</h2>
                  <p>Entiende el comportamiento de us lclientes haciendo segimiento de lo que más vende</p>
                </div>
                <div class="item_det">
                  <h2>Ticket promedio</h2>
                  <p>Puedes ver el promedio de loque gasta un cliente en cada compra</p>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <div class="clear"></div>
      </section>
      <!--Fin Descripcion-->
      <Footer></Footer>
    </section>
    <!--Fin Content_all-->
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Home',
  components: {
    Header,
    Footer
  }
}
</script>
