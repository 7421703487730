<template>
  <div>
    <vsa-list class="accordion-responsive">
      <vsa-item>
        <vsa-heading>PRODUCTOS</vsa-heading>
        <vsa-content>
          <ul class="collapse-links">
            <li>
              <a href="/brain" class>Brain</a>
            </li>
            <li>
              <a href="/messenger-bot" class>Messenger Bot</a>
            </li>
            <li>
              <a href="/web-responsive" class>Web Responsive</a>
            </li>
            <li>
              <a href="/app-movil" class>Premium App</a>
            </li>
            <li>
              <a href="/marketing" class>Marketing</a>
            </li>
          </ul>
        </vsa-content>
      </vsa-item>

      <vsa-item>
        <vsa-heading>MODELOS DE VENTA</vsa-heading>
        <vsa-content>
          <ul class="collapse-links">
            <li>
              <router-link to="/como-funciona">Eat In</router-link>
            </li>
            <li>
              <router-link to="/como-funciona">Take Out</router-link>
            </li>
            <li>
              <router-link to="/como-funciona">Delivery</router-link>
            </li>
          </ul>
        </vsa-content>
      </vsa-item>

      <vsa-item>
        <vsa-heading>REDES</vsa-heading>
        <vsa-content>
          <ul class="collapse-links">
            <li>
              <a href="https://www.facebook.com/InOutDelivery/?fref=ts" target="_blank">Facebook</a>
            </li>
            <li>
              <a href="https://www.instagram.com/inout.delivery/" target="_blank">Instagram</a>
            </li>
            <li>
              <a href="https://twitter.com/InOutDelivery" target="_blank">Twitter</a>
            </li>
          </ul>
        </vsa-content>
      </vsa-item>

      <vsa-item>
        <vsa-heading>VENTAS</vsa-heading>
        <vsa-content>
          <ul class="collapse-links">
            <li>
              <a
                onclick="window.open('http://m.me/InOutDelivery', 'Chat en linea', 'width=600px, height=600px')"
              >Chat en linea</a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/watch?v=yUAE7ei4TjI"
                class="modalvideo"
                data-effect="mfp-zoom-out"
              >Video comercial</a>
            </li>
            <li>
              <a
                href="https://pipedrivewebforms.com/form/6e21957e546eed3fa9b9081d69ab5e2f610200"
                target="_blank"
              >Solicita un demo</a>
            </li>
            <li>
              <a href>+57 4 5167770 Ext. 1176</a>
            </li>
          </ul>
        </vsa-content>
      </vsa-item>

      <vsa-item>
        <vsa-heading>
          COLOMBIA
          <img src="assets/images/icon_es.png" />
        </vsa-heading>
        <vsa-content>
          <ul class="collapse-links">
            <li>
              <router-link to="/contrato">Contrato</router-link>
            </li>
            <li>
              <a href>Soporte</a>
            </li>
          </ul>
        </vsa-content>
      </vsa-item>
    </vsa-list>
  </div>
</template>

<script>
import 'vue-simple-accordion/dist/vue-simple-accordion.css'
import { VsaList, VsaItem, VsaHeading, VsaContent } from 'vue-simple-accordion'

export default {
  components: {
    VsaList,
    VsaItem,
    VsaHeading,
    VsaContent
  }
}
</script>

<style>
.vsa-list{
  --vsa-text-color: gray;
}

.accordion-responsive {
  display: none;
  background: #1d1d1b;
}

.vsa-item__trigger:focus,
.vsa-item__trigger:hover {
  background: #1d1d1b;
}

.vsa-item--is-active {
  color: white !important;
}

.collapse-links li {
  margin: 20px 0;
}

.collapse-links li a {
  color: gray;
}

.collapse-links li a:hover {
  color: white;
  text-decoration: underline;
}

@media only screen and (max-width: 642px) {
  .accordion-responsive {
    display: block;
  }
}
</style>
