<template>
  <div>
    <!--Content_all-->
    <section class="content_all cont_int product_intern produc_c2">
      <Header></Header>
      <div class="wrapper_m">
        <section class="titulo">
          <h1>MARKETING</h1>
          <div class="tab_tittle right">
            <a href="marketing">
              <span>BTL/POP</span>
            </a>
            <a href="marketing-google">
              <span>Google</span>
            </a>
            <a href="marketing-facebook">
              <span>Facebook</span>
            </a>
            <a href="marketing-instagram">
              <span>Instagram</span>
            </a>
            <!--<a href="marketing-detail" class="active">Precio</a>-->
          </div>
        </section>
      </div>

      <!--Descripcion-->
      <section class="wrapper_m">
        <section class="paddg block">
          <section class="detail_product_price padding">
            <div class="small-12 medium-6 large-6 columns">
              <ul class="detail_slide">
                <li class="item">
                  <img src="assets/images/productos/marketing/detail.jpg" style="margin: 4px auto;" />
                </li>
                <li class="item">
                  <img
                    src="assets/images/productos/marketing/btl/img1.jpg"
                    style="margin: 4px auto;"
                  />
                </li>
              </ul>
            </div>
            <div class="small-12 medium-6 large-6 columns padding">
              <div class="txt">
                <p>Para adquirir nuestrs servicios de marketing, debes elegir alguno de nuestros productos de venta online</p>
                <ul class="list">
                  <li>
                    <a href="messenger-bot" class="morado">Messenger Bot</a>
                  </li>
                  <li>
                    <a href="app-movil" class="morado">App Móvil</a>
                  </li>
                  <li>
                    <a href="web-responsive" class="morado">Web Responsive</a>
                  </li>
                </ul>
                <p class="block m_t_30 m_b_30">* Incluye todas las características.</p>
                <!-- <a href="" class="btnb blue m_b_20">CREAR UNA CUENTA GRATIS</a>   -->
                <p>¿Necesitas ayuda?</p>
                <p>
                  <a
                    onclick="window.open('http://m.me/InOutDelivery', 'Chat en linea', 'width=600px, height=600px')"
                  >Chatea ahora</a> o llamanos +57 319 790 7667
                </p>
              </div>
            </div>
          </section>
        </section>
        <div class="clear"></div>
      </section>
      <!--Fin Descripcion-->

      <section class="wrapper_m p_b_40">
        <div class="bg_acodeon">
          <a href="javascript:void(0)" class="modificador_btn">
            ¿Qué necesito para empezar?
            <i class="fa fa-caret-down" aria-hidden="true"></i>
          </a>
          <div class="cont_modif">
            <p>Lo único que necesitas es la voluntad de crecer tus ventas y mejorar la atención de tus clientes, nosotros nos encargamos del resto.</p>
            <p>Tienes que tener preparada lo siguiente:</p>
            <p>1. La carta de tu restaurante, descargar formato.</p>
            <p>2. Los accesos como administrador a tu fanpage.</p>
            <p>3. Crear una cuenta de InOut Delivery.</p>
            <div class="clear"></div>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </section>
    <!--Fin Content_all-->
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Home',
  components: {
    Header,
    Footer
  }
}
</script>
