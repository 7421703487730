<template>
  <div>
    <!--Content_all-->
    <section class="content_all cont_int">
      <Header></Header>
      <section
        class="slide_testi sin_margin"
        style="background-image:url('/assets/images/como_funciona/bg_como_funciona.png')"
      >
        <div class="wrapper_m">
          <a
            href="https://www.youtube.com/watch?v=yUAE7ei4TjI"
            class="modalvideo play animated bounceInUp"
            data-effect="mfp-zoom-out"
          >
            <img src="/assets/images/textimonios/icon_play.png" />
          </a>
        </div>
      </section>

      <!--Productos-->
      <section class="product_all relative padd_como_f">
        <!--Beneficios-->
        <section class="navtwo">
          <div class="relative">
            <ul>
              <li>
                <a href="#omnicanalidad">
                  <b>OMNICANALIDAD</b>
                </a>
              </li>
              <li>
                <a href="#eatIn">
                  <b>EAT IN</b>
                </a>
              </li>
              <li>
                <a href="#takeOut">
                  <b>TAKE OUT</b>
                </a>
              </li>
              <li>
                <a href="#delivery">
                  <b>DELIVERY</b>
                </a>
              </li>
            </ul>
          </div>
        </section>
        <!--Fin Beneficios-->

        <section class="all_cat paddg slot_about" id="omnicanalidad">
          <section class="wrapper_s wow fadeInUp">
            <div class="block">
              <h1>Ominicanalidad</h1>
              <p>Todos tus canales de venta sincronizados en una misma plataforma para tu negocio.</p>
            </div>
            <img src="/assets/images/como_funciona/img1.png" />
            <p>No tendras necesidad de tener una plataforma independiente para cada uno de tus canales de venta ya que con InOut podras tener una plataforma para controlar tu app, tu web y tu bot.</p>
            <div class="centerBtn">
              <router-link to="/productos" class="link">Ver productos</router-link>
            </div>
          </section>
        </section>

        <section class="all_cat paddg bg_gris slot_about" id="eatIn">
          <section class="wrapper_s wow fadeInUp">
            <div class="block">
              <h1>Eat In</h1>
              <p>Transforma tus experiencias de compra dentro de tus puntos de venta</p>
            </div>
            <div class="centerBtn">
              <router-link to="/productos" class="link">Ver productos</router-link>
            </div>
            <img src="/assets/images/como_funciona/img2.png" />
          </section>
        </section>

        <section class="all_cat paddg slot_about" id="takeOut">
          <section class="wrapper_s wow fadeInUp">
            <div class="block">
              <h1>Take Out</h1>
              <p>Tus clientes piden por la app y lo recogen en un punto de venta. Tu solo te encargas de prepararlos</p>
            </div>
            <div class="centerBtn">
              <router-link to="/productos" class="link">Ver productos</router-link>
            </div>
            <img src="/assets/images/como_funciona/img3.png" />
          </section>
        </section>

        <section class="all_cat paddg bg_gris slot_about" id="delivery">
          <section class="wrapper_s wow fadeInUp">
            <div class="block">
              <h1>Delivery</h1>
              <p>Domicilios online inteligetes para tu negocio</p>
            </div>
            <div class="centerBtn">
              <router-link to="/productos" class="link">Ver productos</router-link>
            </div>
            <img src="/assets/images/como_funciona/img4.png" />
          </section>
        </section>
      </section>
      <!--Fin Productos-->
      <Footer></Footer>
    </section>
    <!--Fin Content_all-->
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Home',
  components: {
    Header,
    Footer
  }
}
</script>
