<template>
  <div>
    <!--Content_all-->
    <section class="content_all cont_int product_intern">
      <Header></Header>

      <div class="wrapper_m">
        <section class="titulo">
          <h1>CONTRATO</h1>
        </section>
      </div>

      <!--Contrato-->
      <section class="wrapper_m bg_contrato p_b_60">
        <div class="block m_b_40">
          <p>Entre los suscritos, INOUT DELIVERY SAS, sociedad debidamente constituida de conformidad con las leyes colombianas, domiciliada en la ciudad de Medellín, Colombia, representada legalmente por el señor MATEO ESCOBAR JARAMILLO, mayor de edad, vecino y domiciliado en la ciudad de Medellín e identificado con la Cédula de Ciudadanía número 1.094.899.965 de la ciudad de Armenia, y a quien en lo sucesivo y para todos los efectos legales se denominará “INOUT DELIVERY”, por una parte; y ________________________________________, sociedad debidamente constituida de conformidad con las leyes colombianas, domiciliada en la ciudad de ________________, representada legalmente por _____________________________________, mayor de edad, vecino y domiciliado en la ciudad de __________________ e identificado con la Cédula de Ciudadanía número ________________________ de la ciudad de ___________________, y a quien en lo sucesivo y para todos los efectos legales se denominará el “CLIENTE”, y conjuntamente las “PARTES”, se ha celebrado el presente Contrato de ahora en adelnte “El Contrato”.</p>
        </div>

        <div class="block m_b_40">
          <h2>CONSIDERACIONES.</h2>
          <ul>
            <li>
              <p>Que las Partes se encuentran debidamente facultadas para suscribir el presente contrato y no incurren en ningún tipo de inhabilidad o impedimento para contratar.</p>
            </li>
          </ul>
        </div>

        <div class="block m_b_40">
          <h2>DEFINICIONES.</h2>
          <ul>
            <li>
              <p>A. PLATAFORMA: Se refiere al conjunto de productos digitales tales como: aplicaciones móviles, administrador de contenido, gestión de pedidos, y demás tecnología que conforman la plataforma INOUT DELIVERY.</p>
            </li>
            <li>
              <p>B. APP: Se refiere a las aplicaciones móviles nativas para sistemas operativos iOS y Android.</p>
            </li>
            <li>
              <p>C. USUARIO: Es el tercero que descarga la aplicación.</p>
            </li>
            <li>
              <p>D. IMPLEMENTACIÓN: En general, es el conjunto de actividades de planeación, diseño, desarrollo de código, pruebas y puesta en operación de la plataforma para el Cliente.</p>
            </li>
            <li>
              <p>E. CAPACITACIÓN: Es el proceso mediante el cual se realiza una transferencia de conocimientos por parte de INOUT DELIVERY a través de sus capacitadores al Cliente, con el fin de que pueda operar de forma correcta la plataforma.</p>
            </li>
            <li>
              <p>F. ACTUALIZACIÓN: Es el suministro de instalación de cambios de versión o modificaciones de la plataforma o componentes de la misma por ajustes a la funcionalidad y requerimientos de los sistemas operativos, MARKETPLACE y/o mejoras de diseño.</p>
            </li>
            <li>
              <p>G. MARKETPLACE ó TIENDAS: Se refiere a las plataformas proveídas por Apple (App Store) y Google (PlayStore) para el montaje y distribución de las apps.</p>
            </li>
            <li>
              <p>H. PUESTA EN OPERACIÓN: Actividad por la cual la plataforma digital para ventas a domicilio se hace funcional y permite la compra y venta de productos a través de ella.</p>
            </li>
            <li>
              <p>I. PROPUESTA COMERCIAL: Documento [parte fundamental del presente contrato] que define los productos adquiridos por el cliente y su respectivo funcionamiento, define cuáles serán personalizados e implementados por INOUT DELIVERY para constituir la plataforma para ventas a domicilio que el cliente pasará a operar por el tiempo de vigencia del presente contrato.</p>
            </li>
            <li>
              <p>J. CONTENIDOS: Hace referencia a todos los documentos e información que el Cliente suministre a INOUT DELIVERY con el propósito de ser incluidos en la plataforma. Así mismo hace referencia a todos los documentos e información que el Cliente incluya por su cuenta en su aplicación. Los contenidos comprenden, pero no se limitan a imágenes, textos, fotos, videos, audios y vínculos. INOUT DELIVERY está autorizado para utilizar el contenido suministrado por el cliente únicamente para propósitos relacionados con la implementación de la plataforma.</p>
            </li>
          </ul>
        </div>

        <div class="block m_b_40">
          <h2>CLÁUSULAS.</h2>
          <h3>CLÁUSULA. OBJETO:</h3>
          <p>CONTRATO DE LICENCIAMIENTO PARA LA PLATAFORMA TECNOLÓGICA INOUT DELIVERY.</p>
          <p>INOUT DELIVERY se obliga a:</p>
          <ul>
            <li>
              Otorgar al Cliente una licencia no exclusiva, mundial, de acceso ilimitado con derechos para: usar, copiar, almacenar, archivar, distribuir, transmitir, modificar, traducir, traducir a un formato de audio y/o video y públicamente, EL CONTENIDO que sea de su propiedad y haya subido y/o recopilado a través del uso de LA PLATAFORMA.
              <ul>
                <li>
                  <p>B. Capacitar en Bogotá, Medellín o por SKYPE, hasta cinco (5) funcionarios que el cliente designe para otorgar plena autonomía en el uso y administración de la plataforma objeto del presente contrato.</p>
                </li>
              </ul>
            </li>
          </ul>

          <h3>CLÁUSULA. ALCANCE DE LA LICENCIA:</h3>
          <p>Los productos adquiridos por EL CLIENTE así como las características funcionales de cada uno de ellos serán documentadas en “LA PROPUESTA COMERCIAL” que se anexa y hace parte integral del presente contrato.</p>

          <h3>CLÁUSULA. ALOJAMIENTO DE LA PLATAFORMA:</h3>
          <p>Por motivos de seguridad y confidencialidad sobre el código fuente y la tecnología empleada por INOUT DELIVERY, los servidores de alojamiento serán los mismos donde se aloja toda la plataforma de INOUT DELIVERY, y de ninguna manera se montará la plataforma ni ninguno de sus componentes en servidores o hostings ajenos.</p>

          <h3>CLÁUSULA. TÉRMINO Y PRÓRROGA DEL CONTRATO:</h3>
          <p>El contrato tiene una vigencia indefinida siempre y cuando secumplan las condiciones de dicho contrato por ambas partes.</p>
          <p>El cliente puede cancelar el contrato en cualquier momento a través de un comunicado escrito con un plazo previo de sesenta (60) días calendario.</p>

          <h3>CLÁUSULA. OBLIGACIONES ESPECIALES DE INOUT DELIVERY.</h3>
          <p>INOUT DELIVERY se obliga con el CLIENTE a lo siguiente:</p>
          <ul>
            <li>
              <p>A. El cumplimiento estricto del presente contrato.</p>
            </li>
            <li>
              <p>B. La realización de todas las actividades objeto del presente contrato. INOUT DELIVERY podrá subcontratar aspectos del desarrollo del presente contrato con persona natural o jurídica, sin el consentimiento previo, escrito y expreso del CLIENTE.</p>
            </li>
            <li>
              <p>C. El empleo, bajo su responsabilidad y costo de personal idóneo, conocedor del proyecto y debidamente capacitado para el cabal desarrollo de las actividades objeto del presente contrato.</p>
            </li>
            <li>
              <p>D. El apoyo en capacitación y trabajo conjunto durante la implementación de la plataforma y durante el periodo que esta permanezca al aire.</p>
            </li>
            <li>
              <p>E. Mantener con sus funcionarios la reserva y confidencialidad de información o documentos del CLIENTE que conozca con ocasión del cabal desarrollo de las actividades objeto del presente contrato.</p>
            </li>
            <li>
              <p>F. La adopción de medidas necesarias para mantener, durante la vigencia de este contrato las condiciones técnicas y condiciones de desempeño originalmente aceptados.</p>
            </li>
            <li>
              <p>G. Diseñar el look and feel de la página web responsive, realizar su debida implementación y puesta en operación, para que el cliente la ponga a disposición de sus usuarios para la venta de sus productos.</p>
            </li>
            <li>
              <p>H. Conectar la fanpage de facebook del cliente con el BOT de messenger. I. Otorgar al cliente el uso de la plataforma por un periodo de tiempo indefinido mientras secumplan todas las condiciones del contrato.</p>
            </li>
            <li>
              <p>J. Capacitar en Bogotá, Medellín o por SKYPE, en dos (2) sesiones de hasta 4 horas cada una, hasta cinco (5) funcionarios que el cliente designe para otorgar plena autonomía en el uso y administración de la plataforma objeto del presente contrato.</p>
            </li>
            <li>
              <p>K. En caso de que la empresa INOUT DELIVERY suspenda sus operaciones o cierre permanentemente la empresa, procederá a entregar al los códigos fuente de ingeniería de la plataforma para que el cliente proceda con su administración y mantenimiento.</p>
            </li>
          </ul>

          <h3>CLÁUSULA. OBLIGACIONES DEL CLIENTE.</h3>
          <p>Son obligaciones del CLIENTE:</p>
          <ul>
            <li>
              <p>A. El cumplimento estricto del presente contrato.</p>
            </li>
            <li>
              <p>B. El pago del precio y las comisiones a INOUT DELIVERY según lo estipulado en el presente Contrato.</p>
            </li>
            <li>
              <p>C. Suministrar a INOUT DELIVERY los datos y documentos que sean necesarios para el cabal desarrollo de lasactividades objeto del presente contrato.</p>
            </li>
            <li>
              <p>D. Mantener con sus funcionarios la reserva y confidencialidad de información o documentos de INOUT DELIVERY que conozca con ocasión del cabal desarrollo de las actividades objeto del presente contrato.</p>
            </li>
            <li>
              <p>E. Promocionar la plataforma en sus puntos de venta y demás campañas promocionales que ejecute en cualquier medio de comunicación.</p>
            </li>
          </ul>

          <h3>CLÁUSULA. HABEAS DATA.</h3>
          <p>INOUT DELIVERY se acoge a la ley de habeas data, velando por la protección y confidencialidad de la información personal y financiera recolectada por medio de la plataforma implementada para EL CLIENTE.</p>
          <p>Los datos recolectados son de uso exclusivo del CLIENTE, y este debera tratarlos con la misma responsabilidad y en cumplimiento de la legislación y su propia política de tratamiento de datos y términos y condiciones que EL CLIENTE defina para su plataforma.</p>
          <p>En cualquier caso, INOUT DELIVERY no se hace responsable por el mal manejo que EL CLIENTE otorgue a dicha información.</p>

          <h3>CLÁUSULA. RIESGOS EN EL USO DE LA PLATAFORMA.</h3>
          <p>El CLIENTE reconoce y acepta de forma expresa que el uso de LA PLATAFORMA se realiza a su exclusivo riesgo, es decir, que como todas las plataformas de SOFTWARE, esta también es vulnerable de ataques por parte de hackers, los cuales serán atendidos en la inmediatez por INOUT DELIVERY como parte de su política de soporte y atención al cliente. EL CLIENTE se abstiene y exonera a INOUT DELIVERY por cualquier daño presentado en su plataforma causado por ataques de HACKERS y caídas del servidor.</p>

          <h3>CLÁUSULA. NOMBRAMIENTO DE PERSONAL.</h3>
          <p>INOUT DELIVERY utilizará el personal idóneo y suficiente que seleccionará, contratará y remunerará autónoma e independientemente del CLIENTE para cumplir por sus propios medios y absoluta autonomía técnica y directiva las obligaciones que contrae. El CLIENTE no tiene ninguna relación legal o laboral con el personal o subcontratistas de INOUT DELIVERY y por lo tanto no adquiere compromisos ni obligaciones con personal alguno, en razón de las actividades objeto del presente contrato.</p>

          <h3>PARÁGRAFO:</h3>
          <p>El cliente se compromete a no contratar personal de INOUT DELIVERY durante la ejecución del presente contrato y un (1) años más posterior a su finalización.</p>
          <p>En caso de que el Cliente incumpla con alguna de las anteriores prohibiciones, este deberá reconocer a favor de INOUT DELIVERY una suma equivalente a los ingresos que el empleado o contratista hubiera generado para INOUT DELIVERY durante el siguiente año mas un costo de oportunidad del 40% sobre dicho equivalente. Esta suma deberá ser pagada a INOUT DELIVERY a título de multa sin objeción alguna.</p>

          <h3>CLÁUSULA. PROCESO Y TIEMPOS DE IMPLEMENTACIÓN:</h3>
          <p>El cliente reconoce que la correcta implementación de la plataforma y los canales de venta requieren de conexiones e integraciones con sistemas de terceros tales como redes sociales, Administradores de dominios y hosting, sistemas POS, sistemas ERP, Sistemas de facturación y sistemas de gestión logística , y el cliente se compromete a facilitar toda la información requerida en el momento que se le requiera para no interrumpir el flujo de trabajo de InOut Delivery.</p>
          <p>El tiempo que toma el diseño, la implementación, integración de sistemas de terceros, testeo y puesta en operación depende de la cantidad de clientes que hayan en lista de espera y de la disponibilidad del equipo de producción de InOut Delivery. El Cliente será informado y la fecha de entrega será pactada en “El Cronograma” que se anexa y hace parte integral del presente Contrato.</p>

          <h3>CLÁUSULA. CAMBIOS Y ADICIONES:</h3>
          <p>INOUT DELIVERY se reserva el derecho de aceptar o rechazar la solicitud del desarrollo de módulos o funciones adicionales sobre la plataforma. En caso tal que INOUT DELIVERY acepte por escrito por medio de un otrosí al presente CONTRATO, se realizará una cotización sobre las horas estimadas para su desarrollo a una tarifa de $120,000 por hora.</p>

          <h3>CLÁUSULA. EXCLUSIONES:</h3>
          <p>No son obligaciones de INOUT DELIVERY bajo el presente Contrato las siguientes:</p>
          <ul>
            <li>
              <p>A. Crear, corregir o digitalizar Contenidos para la PLATAFORMA implementada para el Cliente, ni para sus redes sociales ni para ningún otro canal de venta de su propiedad.</p>
            </li>
            <li>
              <p>B. Administrar los contenidos de la PLATAFORMA.</p>
            </li>
            <li>
              <p>C. Entregar al Cliente copia de códigos fuente de sus desarrollos tecnológicos.</p>
            </li>
          </ul>

          <h3>CLÁUSULA. NO CESIÓN DE DERECHOS DE AUTOR E INCLUSIÓN DE MARCA EN LA APLICACIÓN.</h3>
          <p>INOUT DELIVERY declara que dispone de todas las licencias y permisos necesarios para el desarrollo de la PLATAFORMA. El cliente reconoce y acepta que la TECNOLOGÍA es propiedad de INOUT DELIVERY, y por lo tanto no podrá modificarla ni cederla a terceros bajo ningún motivo o circunstancia.</p>

          <p>
            <strong>PARÁGRAFO PRIMERO:</strong>
          </p>
          <p>Todos los canales de venta que InOut Delivery haya implementado y puesto en operación para El Cliente, llevarán la firma: “Powered by InOut Delivery”</p>

          <p>
            <strong>PARÁGRAFO SEGUNDO:</strong>
          </p>
          <p>En caso que el cliente remueva de la firma, INOUT DELIVERY se reserva el derecho de iniciar las acciones legales pertinentes por violación de derechos de autor.</p>

          <h3>CLÁUSULA. INDEMNIDAD.</h3>
          <p>El CLIENTE asume plena responsabilidad por los contenidos publicados en la APLICACIÓN y por el uso que se haga de la misma. Por tanto, el Cliente se compromete a mantener indemne a INOUT DELIVERY frente a cualquier tipo de reclamo de terceros extrajudicial o judicialmente.</p>

          <h3>CLÁUSULA. COPIA DE SEGURIDAD</h3>
          <p>INOUT DELIVERY es responsable de mantener copia de seguridad de los Contenidos y bases de datos de la PLATAFORMA. Las copias de seguridad se realizan semanalmente y se almacenan únicamente las tres (3) últimas copias de seguridad.</p>

          <h3>CLÁUSULA. PACTO ARBITRAL</h3>
          <p>Toda controversia o diferencia relativa a este Contrato, se resolverá por un Tribunal de Arbitramento presentado ante el Centro de Arbitraje y Conciliación de la Cámara de Comercio de Bogotá, el cual estará sujeto a sus reglamentos, de acuerdo con las siguientes reglas:</p>

          <ul>
            <li>
              <p>A. El Tribunal estará integrado por: 3 árbitros designados por las partes de común acuerdo. En caso de que no fuere posible, los árbitros serán designados por el Centro de Arbitraje y Conciliación de la Cámara de Comercio de Bogotá, a solicitud de cualquiera de las Partes.</p>
            </li>
            <li>
              <p>B. El Tribunal decidirá en derecho.</p>
            </li>
            <li>
              <p>C. El Tribunal sesionará en las instalaciones del Centro de Arbitraje y Conciliación de la Cámara de Comercio de Bogotá.</p>
            </li>
            <li>
              <p>D. La secretaria del Tribunal estará integrada por un miembro de la lista oficial de secretarios del Centro de Arbitraje y Conciliación de la Cámara de Comercio de Bogotá.</p>
            </li>
          </ul>

          <h3>CLÁUSULA. EFECTO DE ANULACIÓN</h3>
          <p>Este Contrato constituye el acuerdo único e integral entre las partes y deja sin valor cualquier otro convenio verbal o escrito que se hubiere realizado anteriormente entre éstas sobre el mismo asunto.</p>

          <h3>CLÁUSULA. TERMINACIÓN</h3>
          <p>El presente Contrato terminará por las siguientes causales:</p>
          <ul>
            <li>
              <p>1. Mutuo acuerdo entre las Partes.</p>
            </li>
            <li>
              <p>2. Incumplimiento grave de alguna de las partes</p>
            </li>
          </ul>
          <p>En fé de lo expuesto, se firman DOS (2) ejemplares iguales del presente Contrato en la ciudad de _________________, el día _________ de ___________________ de 2016.</p>
        </div>

        <div class="block m_b_40">
          <h2>Anexo 1: Acuerdo de pagos.</h2>
          <p>Los precios y formas de pago para cada uno o el total de los productos adquiridos son los que se establecen a continuación:</p>
          <p>
            <strong>4% sobre el valor de las ventas realizadas a través productos adquiridos.</strong>
          </p>
          <h3>PARÁGRAFO:</h3>
          <p>El costo asociado a la pasarela de pago online Tpaga es un costo ajeno al presente CONTRATO y depende de la negociación o las tarifas establecidas por el tercero. Este costo deberá ser asumido por el CLIENTE en caso tal que desee la activación de dicha funcionalidad.</p>
        </div>
      </section>
      <!--Fin Contrato-->

      <Footer></Footer>
    </section>
    <!--Fin Content_all-->
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Home',
  components: {
    Header,
    Footer
  }
}
</script>
