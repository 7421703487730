<template>
  <header>
    <Slide class="burger">
      <router-link to="/">
        <span>INICIO</span>
      </router-link>
      <router-link to="/como-funciona">
        <span>CÓMO FUNCIONA</span>
      </router-link>
      <router-link to="/productos">
        <span>PRODUCTOS</span>
      </router-link>
      <a
        href="https://pipedrivewebforms.com/form/6e21957e546eed3fa9b9081d69ab5e2f610200"
        target="_blank"
      >
        <span>SOLICITA UN DEMO</span>
      </a>
    </Slide>
    <section class="wrapper_m">
      <router-link to="/" class="logo">
        <img src="/assets/images/logo.svg" alt="Inoutdelivery.com" />
      </router-link>
      <nav class="nav-bar">
        <ul>
          <li class="phone">
            <a href="javascript:void(0)">+57 319 790 7667</a>
          </li>
          <li>
            <router-link to="/">INICIO</router-link>
          </li>
          <li>
            <router-link to="/como-funciona">CÓMO FUNCIONA</router-link>
          </li>
          <li>
            <router-link to="/productos">PRODUCTOS</router-link>
          </li>
          <li>
            <a
              href="https://pipedrivewebforms.com/form/6e21957e546eed3fa9b9081d69ab5e2f610200"
              target="_blank"
            >SOLICITA UN DEMO</a>
          </li>
          <!-- <li><a href="testimonios">TESTIMONIOS</a></li>  -->
          <!-- <li class="right registe"><a href="registrarme">CREAR CUENTA</a></li>
          <li class="right login"><a href="iniciar-sesion">INGRESAR</a></li>-->
        </ul>
      </nav>
      <!-- <div class="burger">
        <ul>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>-->
      <!--Nav Responsive-->
      <section class="nav_movil">
        <ul class="nav">
          <li>
            <router-link to="/">INICIO</router-link>
          </li>
          <li>
            <router-link to="/como-funciona">CÓMO FUNCIONA</router-link>
          </li>
          <li>
            <router-link to="/productos">PRODUCTOS</router-link>
          </li>
          <li>
            <a
              href="https://pipedrivewebforms.com/form/6e21957e546eed3fa9b9081d69ab5e2f610200"
              target="_blank"
            >SOLICITA UN DEMO</a>
          </li>
          <!-- <li><a href="testimonios">TESTIMONIOS</a></li>      -->
          <!-- <li><a href="registrarme">CREAR CUENTA</a></li>
          <li><a href="iniciar-sesion">INGRESAR</a></li>-->
        </ul>
        <!-- <a href="tel:312 123 1232" class="phone">(+57) 312 123 1232</a> -->
      </section>
      <section class="over_nav"></section>
      <!--Fin Nav Responsive-->
    </section>
  </header>
</template>

<script>
import { Slide } from 'vue-burger-menu'

export default {
  name: 'Header',
  components: {
    Slide
  }
}
</script>

<style>
.router-link-exact-active, .router-link-exact-active span {
  color: rgba(255, 255, 255, .5);
}

.bm-burger-bars {
  background-color: white;
}

.bm-menu {
  background-color: #1d1d1b;
}

.bm-burger-button {
  left: 5px;
  top: 10px;
  z-index: 999;
}

.bm-item-list {
  margin: 0;
}
</style>
