<template>
  <div>
    <carousel v-bind="opc">
       <slide>
        <img class="img-fluid" src="/assets/images/productos/app1.png" alt="app-inoutdelivery" />
      </slide>
      <slide>
        <img class="img-fluid" src="/assets/images/productos/app2.png" alt="app-inoutdelivery" />
      </slide>
      <slide>
        <img class="img-fluid" src="/assets/images/productos/app3.png" alt="app-inoutdelivery" />
      </slide>
      <slide>
        <img class="img-fluid" src="/assets/images/productos/app4.png" alt="app-inoutdelivery" />
      </slide>
      <slide>
        <img class="img-fluid" src="/assets/images/productos/app5.png" alt="app-inoutdelivery" />
      </slide>
    </carousel>
  </div>
</template>

<style>
.img-fluid {
  width: 60% !important;
  height: auto;
}
</style>

<script>
import { Carousel, Slide } from 'vue-carousel'

export default {
  components: {
    Carousel,
    Slide
  },
  data () {
    return {
      opc: {
        perPage: 3,
        autoplay: true,
        loop: true
      }
    }
  }
}
</script>
