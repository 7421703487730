<template>
  <div>
    <!--Content_all-->
    <section class="content_all">
      <Header></Header>
      <!-- Inicio Slider -->
      <Carousel></Carousel>
      <!--Fin Slider-->

      <!-- Inicio Features -->
      <section class="bg_feature">
        <ul class="row">
          <li class="small-12 medium-6 large-3 columns">
            <a href="como-funciona#eatIn">
              <div class="box_feat">
                <h2>Eat-in</h2>
                <p>La experiencia de comer en un lugar.</p>
                <img src="/assets/images/dest1.png" alt="Eat-in" />
              </div>
            </a>
          </li>
          <li class="small-12 medium-6 large-3 columns">
            <a href="/como-funciona#takeOut">
              <div class="box_feat">
                <h2>Take Out</h2>
                <p>Tu lo preparas, tus clientes lo recogen.</p>
                <img src="/assets/images/dest2.png" alt="Take Out" />
              </div>
            </a>
          </li>
          <li class="small-12 medium-6 large-3 columns">
            <a href="como-funciona#delivery">
              <div class="box_feat">
                <h2>Delivery</h2>
                <p>Ve a donde están todos tus clientes</p>
                <img src="/assets/images/dest3.png" alt="Delivery" />
              </div>
            </a>
          </li>
          <li class="small-12 medium-6 large-3 columns">
            <a href="marketing">
              <div class="box_feat">
                <h2>Marketing</h2>
                <p>Que todos hablen de tu restaurante</p>
                <img src="/assets/images/dest4.png" alt="Marketing" />
              </div>
            </a>
          </li>
        </ul>
      </section>

      <Footer></Footer>
    </section>
    <!--Fin Content_all-->
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Carousel from '@/components/Carousel.vue'

export default {
  name: 'Home',
  components: {
    Header,
    Footer,
    Carousel
  }
}
</script>
