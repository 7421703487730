<template>
  <div>
    <!--Content_all-->
    <section class="content_all cont_int product_intern">
      <Header></Header>
      <div class="wrapper_m">
        <section class="titulo">
          <h1>
            <a href="messenger-bot">MESSENGER BOT</a>
          </h1>
          <div class="tab_tittle right">
            <a href="https://www.messenger.com/t/InOutBotDemo/" target="_blank">
              <span>Demo</span>
            </a>
            <a href="messenger-bot-caracteristicas">
              <span>Características</span>
            </a>
            <!--<a href="messenger-bot-detail">Precio</a>-->
          </div>
        </section>
      </div>

      <!--Descripcion-->
      <section class="wrapper_m">
        <ul class="detail_1">
          <li class="paddg block">
            <section class="flex">
              <div class="small-12 medium-6 large-5 columns">
                <img src="assets/images/productos/menssenger/img1.jpg" />
              </div>
              <div class="small-12 medium-6 large-7 columns">
                <div class="txt wow fadeInUp">
                  <h2 class="blue">Chat center automático</h2>
                  <p>Chatea con tus clientes como si fueras uno más de sus amigos de facebook. La única diferencia es que un bot lo hará por ti y se encargará de vender todos tus productos, para ser entregados a domicilio o tomar una solicitud de reserva, todo esto dentro de facebook messenger.</p>
                </div>
              </div>
            </section>
          </li>
          <li class="paddg block">
            <section class="flex">
              <div class="small-12 medium-6 large-7 columns">
                <div class="txt wow fadeInUp txt_right">
                  <h2 class="blue">Actualizaciones del pedido</h2>
                  <p>A través del Brain podrás actualizar los diferentes estados de tu pedido e inmediatamente el bot enviará un mensaje a tu cliente.</p>
                </div>
              </div>
              <div class="small-12 medium-6 large-5 columns">
                <img src="assets/images/productos/menssenger/img2.jpg" />
              </div>
            </section>
          </li>
          <li class="paddg block">
            <section class="flex">
              <div class="small-12 medium-6 large-5 columns">
                <img src="assets/images/productos/menssenger/img3.jpg" />
              </div>
              <div class="small-12 medium-6 large-7 columns">
                <div class="txt wow fadeInUp">
                  <h2 class="blue">Códigos directos</h2>
                  <p>Imagenes inteligentes para que tus clientes accedan directamente desde su celular con solo utilizar la cámara.</p>
                </div>
              </div>
            </section>
          </li>
        </ul>
      </section>
      <!--Fin Descripcion-->

      <section
        class="paddg block promo_mensseng"
        style="background-image: url('assets/images/productos/menssenger/bg_mensengger.jpg')"
      >
        <h3 class="wow fadeInDown">Nos encantan los Bots</h3>
        <div class="stadisc">
          <div class="relative">
            <h4>1 billón de usuarios estan en Messenger</h4>
            <div class="load_stadisc">
              <span style="width: 100%" class="wow bounceInLeft"></span>
            </div>
            <div class="load_stadisc">
              <span style="width: 10%" class="wow bounceInLeft"></span>
            </div>
            <p>más 150 millones de usuarios en latinoamerica</p>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </section>
    <!--Fin Content_all-->
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Home',
  components: {
    Header,
    Footer
  }
}
</script>
