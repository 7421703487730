<template>
  <div>
    <!--Content_all-->
    <section class="content_all cont_int product_intern produc_c2">
      <Header></Header>
      <div class="wrapper_m">
        <section class="titulo">
          <h1>MARKETING</h1>
          <div class="tab_tittle right">
            <a href="marketing">
              <span>BTL/POP</span>
            </a>
            <a href="marketing-google">
              <span>Google</span>
            </a>
            <a href="marketing-facebook">
              <span>Facebook</span>
            </a>
            <a href="marketing-instagram" class="active">
              <span>Instagram</span>
            </a>
            <!--<a href="marketing-detail">Precio</a>-->
          </div>
        </section>
      </div>

      <!--Descripcion-->
      <section class="wrapper_m p_b_30">
        <ul class="detail_1">
          <li class="paddg block">
            <section class="flex">
              <div class="small-12 medium-6 large-7 columns">
                <img src="assets/images/productos/marketing/instagram/img1.jpg" />
              </div>
              <div class="small-12 medium-6 large-5 columns">
                <div class="txt">
                  <h2 class="morado">Construye una comunidad de seguidores</h2>
                  <p>Utiliza tus contenidos para crear cercanía con tus clientes transfórmate en uno de sus compañeros de vida y conocelos para mejorar tu servicio y productos.</p>
                </div>
              </div>
            </section>
          </li>
          <li class="paddg block">
            <section class="flex">
              <div class="small-12 medium-6 large-5 columns">
                <div class="txt txt_right">
                  <h2 class="morado">Redirecciona una visita</h2>
                  <p>Utiliza los botones, hashtags y links para llevar a otras redes, blogs, páginas o descarga de aplicaciones.</p>
                </div>
              </div>
              <div class="small-12 medium-6 large-7 columns">
                <img src="assets/images/productos/marketing/instagram/img2.jpg" />
              </div>
            </section>
          </li>
          <li class="paddg block">
            <section class="flex">
              <div class="small-12 medium-6 large-7 columns">
                <img src="assets/images/productos/marketing/instagram/img3.jpg" />
              </div>
              <div class="small-12 medium-6 large-5 columns">
                <div class="txt">
                  <h2 class="morado">Herramientas de análisis automáticas</h2>
                  <p>Cuenta con reportes sobre las publicaciones, alcances de personas, visualización de perfil y mucho más.</p>
                </div>
              </div>
            </section>
          </li>
        </ul>
      </section>
      <!--Fin Descripcion-->
      <Footer></Footer>
    </section>
    <!--Fin Content_all-->
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Home',
  components: {
    Header,
    Footer
  }
}
</script>
